import { TabView, TabPanel } from "primereact/tabview";
import CalculatorInputs from "./calculator-inputs";
import GainSelector from "./gain-selector";

const CalculatorAndGain = ({
  inputs,
  handlers,
  handleReset,
  isRateDisabled,
  isBlendedOn,
}) => {
  const { handleInput, handleTax, handleGain } = handlers;
  return (
    <>
      <TabView id='tab-view-stock'>
        <TabPanel header='Stock Calculator' id='stock-calculator'>
          <CalculatorInputs
            inputs={inputs}
            handleInput={handleInput}
            handleReset={handleReset}
            handleTax={handleTax}
            isRateDisabled={isRateDisabled}
            isBlendedOn={isBlendedOn}
          />
        </TabPanel>
        <TabPanel header='Gain Selector' id='gain-selector'>
          <GainSelector
            gainValue={inputs.gainValue}
            handler={handleGain}
            isBlendedOn={isBlendedOn}
          />
        </TabPanel>
      </TabView>
    </>
  );
};

export default CalculatorAndGain;
