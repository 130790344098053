// Adjusted S&P from 20000-2022
export const sp500_adjusted = [
  -8.92, -11.67, -22.15, 28.5, 10.74, 4.77, 15.64, 5.39, -37.02, 26.49, 14.91,
  1.97, 15.82, 32.18, 13.51, 1.25, 11.82, 21.67, -4.52, 31.33, 18.02, 28.41,
  -18.11,
];
// VBTIX from 2000-2022
export const VBTIX = [
  11.52, 8.55, 8.39, 4.1, 4.36, 2.53, 4.4, 7.05, 5.19, 6.09, 6.58, 7.72, 4.18,
  -2.14, 5.9, 0.41, 2.61, 3.57, -0.01, 8.73, 7.74, -1.65, -11.46,
];

//Everything below is not used
// Adjusted S&P from 2003-2022
export const sp500_adjusted_2003_2022 = [
  28.5, 10.74, 4.77, 15.64, 5.39, -37.02, 26.49, 14.91, 1.97, 15.82, 32.18,
  13.51, 1.25, 11.82, 21.67, -4.52, 31.33, 18.02, 28.41, -18.11,
];
export const sp500_adjusted_2000_2022 = [
  -8.92, -11.67, -22.15, 28.5, 10.74, 4.77, 15.64, 5.39, -37.02, 26.49, 14.91,
  1.97, 15.82, 32.18, 13.51, 1.25, 11.82, 21.67, -4.52, 31.33, 18.02, 28.41,
  -18.11,
];

// VBTIX from 2003-2022
export const VBTIX_2003_2022 = [
  4.1, 4.36, 2.53, 4.4, 7.05, 5.19, 6.09, 6.58, 7.72, 4.18, -2.14, 5.9, 0.41,
  2.61, 3.57, -0.01, 8.73, 7.74, -1.65, -11.46,
];

export const VBTIX_2000_2022 = [
  11.52, 8.55, 8.39, 4.1, 4.36, 2.53, 4.4, 7.05, 5.19, 6.09, 6.58, 7.72, 4.18,
  -2.14, 5.9, 0.41, 2.61, 3.57, -0.01, 8.73, 7.74, -1.65, -11.46,
];

// Bloomburg Aggregate from 2002-2021,
export const BAGG = [
  10.23, 3.63, 4.1, 2.06, 4.12, 6.97, 5.24, 5.93, 6.54, 7.84, 4.21, -2.02, 5.97,
  1.14, 3.25, 3.54, 0.01, 8.72, 3.76, -1.5,
];
// Bloomburg Aggregate from 2000-2021,
export const BAGG_2000_2021 = [
  11.63, 8.43, 10.23, 3.63, 4.1, 2.06, 4.12, 6.97, 5.24, 5.93, 6.54, 7.84, 4.21,
  -2.02, 5.97, 1.14, 3.25, 3.54, 0.01, 8.72, 3.76, -1.5,
];

// 2021 is 28.53 and 2020 is 18.25
// export const vasgx_2000_2019 = [
//   -5.44, -8.86, -15.84, 28.52, 12.58, 6.88, 16.13, 7.46, -34.39, 24.99, 15.06,
//   -2.28, 14.38, 21.2, 7.18, -1.17, 8.33, 19.21, -6.9, 23.13,
// ];
// export const vasgx_2001_2020 = [
//   -8.86, -15.84, 28.52, 12.58, 6.88, 16.13, 7.46, -34.39, 24.99, 15.06, -2.28,
//   14.38, 21.2, 7.18, -1.17, 8.33, 19.21, -6.9, 23.13, 15.45,
// ];
// export const vasgx_2002_2021 = [
//   -15.84, 28.52, 12.58, 6.88, 16.13, 7.46, -34.39, 24.99, 15.06, -2.28, 14.38,
//   21.2, 7.18, -1.17, 8.33, 19.21, -6.9, 23.13, 15.45, 14.35,
// ];

// export const annualStockGains = [
//   13.56, 28.88, -6.24, 19.42, 9.54, -0.73, 11.39, 29.6, 13.41, 0.01, 12.78,
//   23.45, -38.49, 3.53, 13.62, 3.0, 8.99, 26.38, -23.37, -13.04, -10.14, 19.53,
//   26.67, 31.01, 20.26, 34.11, -1.54, 7.06, 4.46, 26.31, -6.56, 27.25, 12.4,
//   2.03, 14.62, 26.33, 1.4, 17.27, 14.76, -9.73,
// ];
