import React, { Fragment } from "react";
import DrawsOverlay from "./draws-overlay.component";
import AnalyzerSummaryField from "./analyzer-summary-field.component";
import { formatCurrency } from "../../../../utilities/numbers";
import { displayedFilingStatusWithMarried } from "../../../../utilities/misc";

const AnalyzerSummaryConditions = ({ conditions }) => {
  return (
    <Fragment>
      <div className='p-shadow-12 br-9'>
        <div className='card analyzer-conditions pad-top-half'>
          <div className='k-flex-between'>
            <div className='w-152px' />
            <p className='font-subtitle my-p5'>Conditions</p>
            <div className='mx-2em my-auto'>
              <DrawsOverlay
                size={2}
                draws={conditions.allDraws}
                incomes={conditions.allIncomes}
                blendeds={conditions.allBlendeds}
              />
            </div>
          </div>

          <div className='k-flex-between px-2'>
            <AnalyzerSummaryField
              label='Start Year'
              value={conditions.startYear}
            />
            <AnalyzerSummaryField label='End Year' value={conditions.endYear} />
            <AnalyzerSummaryField
              label='Settlement'
              value={formatCurrency(conditions.settlement)}
            />
            <AnalyzerSummaryField
              label='Start Balance'
              value={formatCurrency(conditions.startBalance)}
            />
            {/* <DrawsOverlay
              size={2}
              draws={conditions.allDraws}
              incomes={conditions.allIncomes}
            /> */}
            <AnalyzerSummaryField label='Fees' value={`${conditions.fees}%`} />
            <AnalyzerSummaryField
              label='% in Bonds'
              value={conditions.percentBonds}
            />
            <AnalyzerSummaryField
              label='Bond Rate'
              value={`${conditions.bondRate}%`}
            />
            <AnalyzerSummaryField
              label='Filing Status'
              value={displayedFilingStatusWithMarried(
                conditions.filingStatus,
                conditions.yearMarried
              )}
            />
            <AnalyzerSummaryField
              label='State'
              value={conditions.filingState}
            />
            <AnalyzerSummaryField label='Runs' value={conditions.runs} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerSummaryConditions;
