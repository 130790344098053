import React from "react";
import { Card } from "primereact/card";
import CalculatorInputsView from "./calculator-inputs-view";
import TaxFilingView from "./tax-filing-view";
import ButtonFields from "../../../button-fields/button-fields.component";

const CalculatorInputs = ({
  inputs,
  handleInput,
  handleTax,
  handleReset,
  isRateDisabled,
  isBlendedOn,
}) => {
  return (
    <div className='p-shadow-12 br-9'>
      <Card className='stock-calculator'>
        <div className='p-fluid p-grid p-formgrid'>
          <div className='p-col-7'>
            <CalculatorInputsView
              handler={handleInput}
              inputs={inputs}
              isRateDisabled={isRateDisabled}
              isBlendedOn={isBlendedOn}
            />
            <div className='p-offset-3 p-col-6'>
              <ButtonFields
                constants={{
                  label: "Reset",
                  className: "bg-secondary200",
                  icon: "pi pi-undo",
                }}
                disabled={false}
                handler={handleReset}
              />
            </div>
          </div>
          <div className='p-col-5'>
            <TaxFilingView
              handler={handleTax}
              taxFilingParameters={{
                taxFiling: inputs.taxFiling,
                singleToMarried: inputs.singleToMarried,
                marriedYear: inputs.yearMarried,
              }}
            />
            {/* <div className='p-ml-3'>
              <ButtonFields
                constants={{
                  label: "Reset",
                  className: "bg-secondary200",
                  icon: "pi pi-undo",
                }}
                disabled={false}
                handler={handleReset}
              />
            </div> */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CalculatorInputs;
