import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const AnnualGrowthParagraph = ({
  percentBonds,
  bondRate,
  gainCalculationType,
}) => {
  const firstString = `Your SSA’s annual growth model assumes ${
    100 - percentBonds
  }% of the portfolio increases/decreases according to the S&P500 index since 2000 in a randomized order`;
  const secondString = ` and ${percentBonds}% of the portfolio increases at a bond rate of ${bondRate}%.`;
  const secondStringVBTIX = ` and ${percentBonds}% of the portfolio increases at a variable rate according to the VBTIX bond index in concurrence with the S&P500 return for that year. `;
  const secondStringBAGG = ` and ${percentBonds}% of the portfolio increases at a variable rate according to the BAGG bond index in concurrence with the S&P500 return for that year. `;
  let finalString = firstString;
  if (percentBonds < 0.05) finalString += ".";
  else if (gainCalculationType === "sp500_adjusted")
    finalString += secondString;
  else if (gainCalculationType === "sp500_vbtix")
    finalString += secondStringVBTIX;
  else finalString += secondStringBAGG;

  return <Text style={[styles.fontm]}>{finalString}</Text>;
};
export default AnnualGrowthParagraph;
