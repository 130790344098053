import React from "react";
import { Card } from "primereact/card";
import RadioInputGroup from "../../../../lib/radio-input-group";
import { gainParams } from "../setup-params";

const GainSelector = ({ gainValue, handler, isBlendedOn }) => {
  return (
    <div className='p-shadow-12 br-9'>
      <Card className='stock-calculator'>
        <div className=' p-fluid p-grid mt-50px'>
          <div className='p-col-3' />
          <RadioInputGroup
            constants={gainParams.sp500_adjusted}
            value={gainValue}
            handler={handler}
            isDisabled={isBlendedOn}
          />
          <div className='p-col-3' />
          <RadioInputGroup
            constants={gainParams.sp500_vbtix}
            value={gainValue}
            handler={handler}
            isDisabled={isBlendedOn}
          />

          {/* <div className='p-col-3' />
          <RadioInputGroup
            constants={gainParams.sp500_bagg}
            value={gainValue}
            handler={handler}
            isDisabled={isBlendedOn}
          />*/}
        </div>
      </Card>
    </div>
  );
};

export default GainSelector;
